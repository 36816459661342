// Here you can add other styles
@media only screen and (min-width: 769px) {
  .header {
    padding: 3px !important;
  }

  .header-divider {
    margin: 2px !important;
  }

  .settablewidth {
    width: 350px;
  }
}

.marginbottomzero {
  margin-bottom: 0px !important;
}

.simplebar-wrapper .nav-item {
  padding: 0px 15px 0px 15px !important;
  margin-bottom: 10px;
}

/*.customcarousel .carousel-control-prev{
  top:-50%
}
.customcarousel .carousel-control-next{
  top:-50%
}*/
.sidebar-brand {
  background: #ffffff !important;
}

.sidebar-nav .nav-item svg svg {
  fill: #495E7C;
}

.sidebar-nav .nav-item .active svg svg {
  fill: #7366FE;
}

.studentdashboardbox svg svg {
  fill: #ffffff;
}

.sidebar-nav .nav-link.active {
  color: #7366FE !important;
  background: #F3F2FF !important;
  padding: 10px 10px 10px 5px;
  border-radius: 7px;
}

.sidebar-nav .nav-link {
  color: #000 !important;
  // background: rgba(246, 246, 246, 1) !important;
  padding: 10px 10px 10px 5px;
  border-radius: 7px;
}

.sidebar-nav .nav-link svg {
  color: #000 !important;
  margin-top: 1px;
  margin-right: -10px;
}

.sidebar-nav .nav-link.active svg {
  color: #7366FE !important;
  margin-top: 1px;
}

.sidebar {
  background: rgba(246, 246, 246, 1);
  box-shadow: 0px 4px 24px rgba(22, 22, 22, 0.07);
}

.sidebar-nav .icon.icon-lg {
  color: #000 !important;
}

#input-label {
  display: none;
}

.input-wrap {
  width: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 1rem 0 0rem;
  background: #F8F8F8;
  box-sizing: border-box;
  border-radius: 5px;
}

.input-wrap i {
  font-size: 1.5rem;
  color: white;
}

#product-search {
  font-size: 15px;
  color: #000000;
  width: 100%;
  height: 100%;
  background: #F8F8F8;
  border: none;
  outline: none;
  padding: 8px 0px 8px 10px;
  border-radius: 5px;
}

#product-search::placeholder {
  color: #000000;
  opacity: 0.7;
  font-size: 13px;
  ;
}

.fa-times:hover {
  cursor: pointer;
}

.results-wrap {
  width: 50%;
  min-height: 11rem;
  max-height: auto;
  margin-top: 1rem;
  padding: 1rem;
  box-sizing: border-box;
}

.list-item {
  display: inline-flex;
  word-wrap: initial;
  list-style-type: none;
  font-size: 1.2rem;
  line-height: 1.5rem;
  text-transform: capitalize;
  margin-right: 0.5rem;
}

.list-item a {
  color: grey;
  text-decoration: none;
}

.list-item a:hover {
  color: #ffffff;
  transition: 200ms ease-in;
}

@media (max-width: 768px) {
  .input-wrap {
    width: 96%;
  }

  .results-wrap {
    width: 96%;
  }
}

.breadcrumb-item-uppercase {
  text-transform: capitalize;
  color: #000 !important;
  font-size: 17px;
  font-weight: 600;
}

.breadcrumb-item-uppercase a {
  text-decoration: none;
  color: #45bedf;
}

.powerdesign {
  color: white;
  font-size: 11px;
  font-weight: 700;
  padding: 2px 4px;
  position: absolute;
  right: 0px;
  top: -12px;
  background-color: #e52618;
  border-radius: 10px;
}

.positionset {
  position: relative;
  margin-top: 8px;
}

.marginleftZero {
  margin-left: 0px !important;
}

.footer a {
  text-decoration: none;
}

.buttoncolor {
  background: #5788E5;
  border-radius: 5px;
  width: 70px !important;
  height: 30px !important;
  font-size: 14px;
  font-weight: 600;
  line-height: 0px;
  border-color: #5788E5;
  box-shadow: none;
}

.buttoncolor:hover {
  background: #5788E5;
  border-radius: 5px;
  width: 70px !important;
  height: 30px !important;
  font-size: 14px;
  font-weight: 600;
  line-height: 0px;
  border-color: #5788E5;
  box-shadow: none;
}

.hallticketcolor {
  background: #bf2228;
  width: 130px !important;
  border-radius: 5px;
  color: #ffffff;
  border-color: #bf2228;
}

.hallticketcolor:hover {
  background: #bf2228;
  width: 130px !important;
  border-radius: 5px;
  color: #ffffff;
  border-color: #bf2228;
}

.successpayment {
  color: #3FB97B
}

.pendingpayment {
  color: #FF4335
}

.failedpayment {
  color: red
}

.noBorder {
  border: none !important;
}

.boxDesign {
  background: #FFFFFF;
  box-shadow: 0px 0px 5px rgba(144, 164, 204, 0.1);
  border-radius: 5px;
  border: none !important;
}

.headcolor th {
  color: #7A7A7A;
  font-weight: 400;
  font-size: 16px;
}

.headcolor th {
  background: #F3F6F9;
}

.bodycolor td {
  color: #212121;
  font-weight: 400;
  font-size: 16px;
  border-bottom: 1px solid #E2E2E2;
}

.applicationcolor {
  color: #495057;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
}

.timeago {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #34C2E0;
  float: right;
  background: #ECFCFF;
  border-radius: 5px;
  padding: 5px 10px 5px 10px;
}

.submitapplication {
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.0125em;
  color: #495057;
  display: inline-block;
  margin-top: 3px;
}

.headcolor th:first-child {
  padding-left: 15px;
}

.bodycolor td:first-child {
  padding-left: 15px;
}

//Layout design

/*.wrapper .container-fluid{padding-left: 0px !important;}
body .container-fluid{padding-left: 0px !important;}
header .container-fluid{padding-left: 0px !important;}*/
//Dashbord Design
.studentdashboardiconbox img {
  width: 100%
}

.clockdesign {
  margin-top: -5px;
  margin-right: 5px;
}

.studentdashboardbox {
  background: #FFFFFF;
  box-shadow: 0px 0px 5px rgba(144, 164, 204, 0.1);
  border-radius: 5px;
  /*padding: 28px 28px 20px 25px;*/
}

.studentdashboardiconbox {
  padding: 6px;
  border-radius: 5px;
  margin-top: 5px;
}

.color1 {
  background-color: #7366FE;
}

.color2 {
  background-color: #E82370;
}

.studentdashboardheading {
  color: #7A7A7A;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  letter-spacing: 0.0125em;
  margin-top: 3px;
}

.studentdashboardparagragh {
  color: #2B2B2B;
  font-weight: 700;
  font-size: 18px;

  line-height: 22px;
  align-items: center;
  letter-spacing: 0.0125em;
  margin-bottom: 0px;
}

.modal-title {
  margin-left: 8rem;
}

.studentdashboardiconbox svg {
  color: #ffffff;
}

.paddingzero {
  padding: 0px !important;
}

.paddingrightzero {
  padding-right: 0px !important;
}

.margintop45 {
  margin-top: 45px;
}

.boxpadding {
  padding: 29px 0px 29px 15px;
}

@media (max-width: 769px) {
  .mobileonly {
    display: block !important;
  }

  .entrancemark td {
    width: 100%
  }

  .submitapplicationdiv tr {
    display: grid;
  }

  .bodycolor td:first-child,
  .headcolor th:first-child {
    padding-left: 7px !important;
  }

  .headcolor th:first-child {
    padding-left: 7px !important;
  }

  .desktoponly {
    display: none !important;
  }

  .borderbottom {
    border-bottom: 2px solid rgba(73, 94, 124, 0.15);
  }

  .mobilepaddingzero {
    padding: 0px !important;
  }

  .mobilemarginbottom20 {
    margin-bottom: 20px;
  }

  .mobilemarginbottom12 {
    margin-bottom: 12px;
  }

  .mobilemargintop20 {
    margin-top: 20px;
  }

  .mobilemargintop10 {
    margin-top: 10px;
  }

  .mobilemargintop15 {
    margin-top: 15px;
  }

  .adbannerpadding {
    margin-top: 20px;
    ;
  }

  .desktoppositionstatic {
    position: static;
  }

  .invoicedate {
    font-size: 10px !important;
  }

  .marginleft20 {
    margin-left: 0px !important;
  }

  .telephonebox {
    margin-left: 10px !important;
  }

  .telephonetext {
    font-size: 14px !important;
  }

  .customizemargin .react-tabs {
    margin-top: 0px !important;
  }

  .customizemargin .react-tabs__tab-list {
    margin-left: 0% !important;
  }
}

@media (min-width: 1400px) {

  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 100% !important;
  }
}

@media (min-width: 769px) {
  .desktoppaddingleftzero {
    padding-left: 0px !important;
  }

  .desktoponly {
    display: block !important;
  }

  .desktopinlineblock {
    display: inline-block !important;
  }

  .mobileonly {
    display: none !important;
  }

  .borderright {
    border-right: 1px solid rgba(73, 94, 124, 0.15);
  }

  .adbannerpadding {
    padding: 15px 0px;
  }

  .desktoppositionstatic {
    position: static;
  }

  .desktopmarginleft15 {
    margin-left: 15px;
    ;
  }
}


.doashboardslider {
  background: #7366FE;
  border-radius: 5px;
}

.buttonclickhere {
  width: 137px;
  height: 38px;
  background: #FFFFFF;
  border-radius: 5px;
  text-decoration: none;
  color: #7366FE !important;
}

.timerbox {
  background: rgba(255, 255, 255, 0.09);
  border-radius: 5px;
  padding: 5px;
  color: #fff;
  font-size: 17px;
  margin-left: 3.5%;
}

.margintop20 {
  margin-top: 20px;
}

.chickherebutton {
  width: 137px;
  height: 38px;
  background: #FFFFFF;
  border-radius: 5px;
  color: #7366FE;
  padding: 5px;
}

/*.carouselcondition .carousel-inner{
  overflow: visible !important;
}*/
.margintop10 {
  margin-top: 10px;
}

.paddingrightzero {
  padding-right: 0px !important;
}

.circuldesign {
  width: 120px;
  height: 120px;
  margin: 0px auto;
}

.carousel-control-prev-icon {
  background-color: #8478FF;
  border-radius: 50%;
  opacity: 1;
  width: 40px;
  height: 40px;
  filter: initial !important;
  background-size: 20px;
}

.carousel-control-next-icon {
  background-color: rgba(6, 0, 75, 0.35);
  border-radius: 50%;
  opacity: 1;
  width: 40px;
  height: 40px;
  filter: initial !important;
  background-size: 20px;
}

.marginright3 {
  margin-right: 3px;
}

.doughtcolor {
  width: 8px;
  height: 8px;
  background-color: #E52618;
  border-radius: 10px;
  margin-right: 5px;
}

.greencolor {
  width: 8px;
  height: 8px;
  background-color: #3fb97b;
  border-radius: 10px;
  margin-right: 5px;
}

.adbanner {
  background: linear-gradient(90deg, #55ACFF 0%, #B2D9FF 100%);
  border-radius: 5px;
  padding: 25px 15px 25px 20px;
}

.bannerheading {
  font-weight: 700;
  font-size: 32px;
  line-height: 38px;
  align-items: center;
  letter-spacing: 0.0125em;
  display: flex;
  color: #0057AB;
}

.adadmission {
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.0125em;
  color: #2A78C3;
  background: rgba(255, 255, 255, 0.41);
  padding: 8px;
}

.adtext {
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.0125em;
  color: #00369C;
}

.adclickhere {
  background: #155A9C;
  border-radius: 5px;
  padding: 6px 30px 6px 30px;
  color: #fff;
  font-size: 16px;
}

.bg-white-color {
  background-color: #fff;
}

//Application page

.padding13 {
  padding: 15px 20px 15px 15px;
  box-shadow: 0px 0px 5px rgba(144, 164, 204, 0.1);
  border-radius: 5px;
  background: #FFFFFF;
}

.pagedesign .completeapplication {
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.0125em;
  color: #212121;
}

.applicationheading {
  font-weight: 700;
  font-size: 20px;
  line-height: 20px;
  letter-spacing: 0.0125em;
  color: #212121;
  margin-bottom: 10px;
}

.rounddesign {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.0125em;
  color: #9E9E9E;
  margin-bottom: 10px;
}

.rounddesign span {
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  margin-left: 5px;
  align-items: center;
  letter-spacing: 0.0125em;
  color: #212121;
  margin-bottom: 10px;
}

.applynowbutton {
  width: 150px;
  height: 35px;
  background: #7366FE;
  border-radius: 5px;
  padding: 4px;
  color: #ffffff;
  text-align: center;
  margin-bottom: 10px;
}

.boxdesign {
  background: #ffffff;
  border: 1px solid #EAEAEA;
  box-shadow: 7px 0px 0px #7366FE;
  border-radius: 8px 8px 8px 8px;
  padding: 20px 10px 10px 15px;
}

.boxdesign img {
  border-radius: 6px;
}

.margintop40 {
  margin-top: 40px;
}

.applynowbutton a {
  color: #fff;
  text-decoration: none;
}

//Application Form

.formdesign input {
  border: 1px solid #DBDBDB !important;
  border-radius: 4px !important;
  box-shadow: none !important;
  outline: none;
}

.formdesign select {
  border: 1px solid #DBDBDB !important;
  border-radius: 4px !important;
  box-shadow: none !important;
  outline: none;
}

.formdesign label {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #4F555C;
}

.otpbox {
  height: 38px;
  width: 38px;
  margin-right: 10px;
  text-align: center;
}

.otpsmall {
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #8D8D8D;
  margin-left: 10px;
}

.resendbutton {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #7366FE;
  cursor: pointer;
}

.nextbutton {
  width: 132px;
  height: 35px;
  background: #7366FE;
  border-radius: 5px;
  padding: 6px;
  color: #ffffff;
  text-align: center;
  margin-bottom: 10px;
  font-size: 16px;
  line-height: 20px;
  cursor: pointer;
  border-color: transparent;
}

.nextbutton a {
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: #FFFFFF;
  text-decoration: none;
}

.savebutton {
  width: 132px;
  height: 35px;
  background: #ffffff;
  border-radius: 5px;
  padding: 6px;
  color: #7366FE;
  text-align: center;
  margin-bottom: 10px;
  display: inline-block;
  margin-right: 15px;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  cursor: pointer;
}

.savebutton a {
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: #7366FE;
  text-decoration: none;
}

.pullright {
  float: right;
}

.margintop2 {
  margin-top: 2px;
}

.checkboxdesign {
  display: inline-block;
}

.checkboxdesign a {
  margin-left: 5px;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #4F555C;
  text-decoration: none;
}

.boxdesign a {
  text-decoration: none !important;
}

// Form input file

.drop-container {
  position: relative;
  display: flex;
  gap: 6px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 150px;
  padding: 20px;
  background: #ECF3FF;
  border: 1px dashed #7994DE;
  border-radius: 10px;
  color: #444;
  cursor: pointer;
  transition: background .2s ease-in-out, border .2s ease-in-out;
}

.drop-container:hover {
  background: #eee;
  border-color: #111;
}

.drop-container:hover .drop-title {
  color: #222;
}

.drop-title {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.0125em;
  color: #495E7C !important;
}

.drop-title-or {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.0125em;
  color: #495E7C !important;
}

.font {
  color: #222;
  font-size: 16px;
  font-weight: normal;
  transition: color .2s ease-in-out;
}


input[type=file] {
  // width: 135px;
  max-width: 100%;
  color: #444;
  padding: 5px;
  background: transparent;
  border-radius: 5px !important;
  border: none !important;
}

input[type=file]::file-selector-button {
  margin-right: 20px;
  border: none;
  background: #5788E5;
  padding: 10px 20px;
  border-radius: 10px;
  color: #fff;
  cursor: pointer;
  transition: background .2s ease-in-out;
}

input[type=file]::file-selector-button:hover {
  background: #5788E5;
}

.margintop4 {
  margin-top: 4px;
}

.icon-animated {
  width: 25px;
  height: 25px;
  position: relative;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  margin-left: 100px;
}

.icon-animated .tick svg {
  position: absolute;
  left: 5px;
  right: 0;
  top: 5px;
  bottom: 0;
  fill: #fff;
  width: 15px;
  height: 15px;
}

.icon-animated-tick .circle {
  fill: #3FB97B;
  animation-fill-mode: forwards;
  animation-name: circle-pulse;
  animation-duration: 4s;
  animation-iteration-count: 1;
}

.tick-leg1 {
  fill: #fff;
  animation-fill-mode: forwards;
  animation-name: tick-swipe1;
  animation-duration: 1s;
  animation-iteration-count: 1;
  transform: scaleX(0);
  transform-origin: left bottom;
  opacity: 0;
}

.tick-leg2 {
  fill: #fff;
  animation-fill-mode: forwards;
  animation-name: tick-swipe2;
  animation-duration: 2s;
  animation-iteration-count: 1;
  transform: scaleY(0);
  transform-origin: right bottom;
  opacity: 0;
}


@keyframes tick-swipe1 {
  0% {
    opacity: 0;
  }

  10% {
    opacity: 0.5;
  }

  20% {
    opacity: 1;
  }


  100% {
    opacity: 1;
    transform: scaleX(1);
  }
}

@keyframes tick-swipe2 {
  40% {
    opacity: 0;
  }

  47% {
    transform: scaleY(0.15);
    opacity: 1;
  }

  100% {
    transform: scaleY(1);
    opacity: 1;
  }
}

@keyframes circle-pulse {

  0%,
  25%,
  75%,
  100% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.05);
  }
}

.tick {
  width: 100%;
  height: 100%;
  transform: rotate(45deg) scale(0.8);
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
}

.tick-holder {
  transform: scale(0.6);
}

.deletebutton {
  width: 100px;
  height: 30px;
  background: #FFE0DD;
  border-radius: 5px;
  padding: 3px;
  color: #F23E31;
  text-align: center;
  font-size: 16px;
}

.deletebutton a {
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: #F23E31;
  text-decoration: none;
}

.pdfdesign {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.0125em;
  color: #535353;
  min-width: 50%;
  display: inline-block;
}

.filedesign {
  background: #FAFAFA;
  border-radius: 5px;
  padding: 8px;
}

// Support page

.supporttitle {
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.0125em;
  color: #212121;
  background-color: #fff;
  border-bottom: 1px solid #E2E2E2;
}

.timetakentext {
  font-size: 12px;
}

.contactheading {
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  letter-spacing: 0.0125em;
  color: #E82370;
  margin-bottom: 10px;
}

.contactparagraph {
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.0125em;
  color: #212121;
  margin-bottom: 10px;
}

.telephonebox {
  margin-top: 10px;
  margin-left: 30px;
}

.telephonebox svg {
  margin-right: 30px;
  color: #7366FE;
}

.telephonetext {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.0125em;
  color: #8D8D8D;
  margin-top: 10px;
}

.boxicon {
  background-color: #EFEDFF;
  border-radius: 30px;
  width: 40px;
  height: 40px;
  padding-top: 9px;
  padding-left: 11px;
}

.accordion-body {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.0125em;
  color: #8D8D8D;
  text-align: justify;
}

.accordion-button {
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.0125em;
  color: #212121;
  box-shadow: none !important;
  border-radius: 5px;
}

/*.react-tabs__tab-list {
    border-bottom: 1px solid transparent !important;
}
.react-tabs__tab--selected {
  background: #fff !important;
  border-color: transparent !important;
  color: #F99746 !important;
}*/
.react-tabs__tab--selected {
  color: #F99746 !important;
  border: none !important;
  background: #FFF6EF !important;
  border-radius: 50px !important;
  padding: 10px 15px 10px 15px !important;
  font-weight: 700 !important;
  font-size: 16px !important;
  line-height: 19px !important;
  letter-spacing: 0.0125em !important;
  color: #F99746 !important;
}

.numbercircle {
  background-color: #ffffff;
  width: 30px;
  height: 30px;
  position: relative;
  display: inline-block;
  text-align: center;
  border-radius: 15px;
  padding-top: 2px;
  margin-right: 5px;
  border: 1px solid #E7E7E7;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.0125em;
  color: #8D8D8D;
}

.currentblock {
  background-color: #F3F8FF;
  border: 1px solid #F3F8FF;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.0125em;
  color: #1C468A;
}

.linedesign {
  width: 85px;
  display: inline-block;
  height: 3px;
  background-color: #1C468A;
  margin-left: 10px !important;
  margin-right: 10px !important;
  margin: 3px;
}

.linedesign-empty {
  width: 85px;
  display: inline-block;
  height: 3px;
  margin-left: 10px !important;
  margin-right: 10px !important;
  margin: 3px;
  background-size: 16px 4px, 16px 4px, 4px 16px, 4px 16px !important;
  background: linear-gradient(90deg, #8D8D8D 50%, transparent 50%), linear-gradient(90deg, #8D8D8D 50%, transparent 50%), linear-gradient(0deg, #8D8D8D 50%, transparent 50%), linear-gradient(0deg, #8D8D8D 50%, transparent 50%);
}

.padding8 {
  padding: 8px;
}

.circledesign li {
  display: inline-block;
}

.marginleft15 {
  margin-left: 15px;
}

.numbercirclefill {
  background-color: #3FB97B !important;
  border: 1px soild #3FB97B;
  color: #fff;
}

.linedesignfill {
  background-color: #3FB97B !important;
}

.accordion-button {
  background-color: #F8F8F8 !important;
}

.accordion-button:not(.collapsed) {
  background-color: #fff !important;
}

.accordion-item {
  margin-bottom: 15px;
  border: none !important;
  border-radius: 5px;
}

.customizemargin .react-tabs {
  margin-top: -45px;
}

.customizemargin .react-tabs__tab-list {
  margin-left: 20%;
  border: none !important;
  margin-bottom: 10px;
}

.marginleft20 {
  margin-left: 20px;
}

.react-tabs__tab {
  margin-right: 30px;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  letter-spacing: 0.0125em;
  color: #8D8D8D;
}

.yesbutton {
  background: #F3F2FF;
  border-radius: 25px;
  text-align: center;
  padding: 7px 15px 7px 15px;
  color: #7366FE;
  line-height: 20px;
  cursor: pointer;
}

.nobutton {
  background: #F3F2FF;
  border-radius: 25px;
  text-align: center;
  padding: 7px 15px 7px 15px;
  color: #7366FE;
  line-height: 20px;
  cursor: pointer;
}

.dayname {
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.0125em;
  color: #6E61F8;
  display: inline-block;
  width: 100px
}

.appointmenttime {
  display: inline-block;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.0125em;
  color: #4F555C;
  margin-right: 15px;
  margin-top: 5px;
}

.datebox {
  display: inline-block;
  padding: 3px 5px 3px 5px;
  background: #F3F6F9;
  border: 1px solid #DBDBDB;
  border-radius: 4px;
}

.datebox svg {
  margin-right: 5px;
}

.datebox span {
  font-weight: 500;
  font-size: 17px;
  line-height: 22px;
  letter-spacing: 0.0125em;
  color: #4F555C;
}

.timedesign {
  font-weight: 600;
  font-size: 17px;
  line-height: 22px;
  letter-spacing: 0.0125em;
  color: #6E61F8;
  display: inline-block;
  width: 45px;
  margin-left: 30px;
}

.bookingicon {
  background-color: #F3F2FF;
  border-radius: 30px;
  width: 40px;
  height: 40px;
  padding-top: 8px;
  padding-left: 0px;
}

.popupdate {
  background: #F3F6F9;
  border: 1px solid #DBDBDB;
  padding: 7px;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.0125em;
  color: #4F555C;
}

.icon-animated-one {
  width: 72px;
  height: 72px;
  position: relative;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
}

.icon-animated-one .tick svg {
  position: absolute;
  left: 5px;
  right: 0;
  top: 5px;
  bottom: 0;
  fill: #fff;
  width: 50px;
  height: 50px;
}

//Payment details

.invoicetitle {
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.0125em;
  color: #000000;
  margin-top: 8px;
  margin-bottom: 8px;
}

.invoicedate {
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.0125em;
  color: #8D8D8D;
}

.doughtgreencolor {
  display: inline-block;
  margin-left: 15px;
  background-color: #66DB94;
  width: 8px;
  height: 8px;
  border-radius: 5px;
  margin-right: 5px;
}

.amountbox {
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  letter-spacing: 0.0125em;
  color: #212121;
  margin-top: 15px;
  float: right;
}

.amountbox span {
  padding: 5px 10px 5px 10px;
  background: #3FB97B;
  border-radius: 5px;
  color: #fff;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  margin-left: 15px;
}

.icon-animated-payment {
  width: 20px;
  height: 20px;
  position: relative;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  margin-top: -10px;
  margin-left: 1px;
  margin-right: 10px;
}

.icon-animated-payment .tick svg {
  position: absolute;
  left: 3px;
  right: 0;
  top: 3px;
  bottom: 0;
  fill: #3fb97b;
  width: 15px;
  height: 15px;
}

.icon-animated-tick-payment .circle {
  fill: #fff;
  animation-fill-mode: forwards;
  animation-name: circle-pulse;
  animation-duration: 4s;
  animation-iteration-count: 1;
}

.color3 {
  background-color: #34C2E0;
}

.customborder {
  border: 1px solid #E1E1E1;
  border-radius: 5px;
}

.customborder th {
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.0125em;
  color: #212121;
}

.customborder td {
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.0125em;
  color: #5E5E5E;
}

.customborder td span {
  font-weight: 700;
  font-size: 15px;
  line-height: 19px;
  letter-spacing: 0.0125em;
  color: #212121;
  float: right;
}

.custombg {
  background: #f5f5f5;
  box-shadow: 0px 0px 5px rgb(144 164 204 / 10%);
  border-radius: 5px;
  padding: 20px;
}

.downloadinvoice {
  height: 35px;
  background: #7366FE;
  border-radius: 5px;
  padding: 6px;
  color: #ffffff;
  text-align: center;
  margin-bottom: 10px;
  font-size: 16px;
  line-height: 20px;
  cursor: pointer;
}

.downloadinvoice img {
  filter: brightness(0) invert(1);
  margin-top: -5px;
}

.profiletitle {
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  letter-spacing: 0.0125em;
  color: #000000;
}

.profilefont label {
  font-weight: 600;
  font-size: 15px;
  line-height: 19px;
  letter-spacing: 0.0125em;
  color: #535353;
}

.form-control {
  box-shadow: none !important;
}


.img-card {
  margin-top: 20px;
  text-align: center;
}

@keyframes open {
  0% {
    background-position: 166% -25%, 220% -69%;
  }

  100% {
    background-position: 120% -5%, 200% -40%;
  }
}

.imgprofile input[type="file"] {
  display: none;
}

.custom-file-upload {
  border-radius: 50%;
  display: inline-block;
  position: relative;
  padding: 6px;
  cursor: pointer;
  background: linear-gradient(270deg, #3fa1a9, #79f1a4);
  margin-bottom: 25px;
}

.img-wrap {
  position: relative;
  width: 200px;
  height: 200px;
  overflow: hidden;
  border-radius: 50%;
}

.img-upload:before {
  /*content: "\f093";
  font-size: 90px;
  position: absolute;
  padding-top: 80px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: #63d3a6;
  width: 200px;
  height: 200px;
  border-radius: 50%;
  opacity: 0;
  transition: .5s ease;*/
  background-color: #fff;
}

.img-upload:hover:before {
  opacity: 1;
}

.img-wrap img {
  width: auto;
  height: 100%;
}

.pencilicon {
  width: 30px;
  height: 30px;
  background: #FFFFFF;
  border: 2px solid #DDDDDD;
  border-radius: 30px;
  float: right;
}

.pencilicon img {
  width: 15px;
}

.form-select {
  box-shadow: none;
  outline: none;
}

.form-select:click {
  box-shadow: none;
  outline: none;
}

.loginbutton {
  border-radius: 4px;
  cursor: pointer;
  background-color: #558BFF;
  border: none;
  color: #FFFFFF;
  text-align: center;
  font-size: 16px;
  padding: 7px;
  width: 100%;
  transition: all 0.5s;
}

.loginbutton span {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.5s;
}

.loginbutton span:after {
  content: '\00bb';
  position: absolute;
  opacity: 0;
  top: 0;
  right: -20px;
  transition: 0.5s;
}

.loginbutton:hover span {
  padding-right: 25px;
}

.loginbutton:hover span:after {
  opacity: 1;
  right: 0;
}

#studentimage .avatar-img {
  width: 85% !important;
}

.margintop15 {
  margin-top: 15px;
}

#otpfield input {
  width: 32px !important;
  margin-right: 10px !important;
}

.otplinedesign {
  margin-left: 3px;
  margin-right: 3px;
}

.form-check-input {
  box-shadow: none !important;
}

.marginright5 {
  margin-right: 5px;
}

.filedesign a {
  text-decoration: none !important;
  color: #212121;
}

.completeapplication a {
  text-decoration: none !important;
}

a {
  text-decoration: none !important;
}

.form-check-input {
  margin-right: 5px;
}

.butn:link,
.butn:visited {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  // text-transform: uppercase;
  text-decoration: none;
  background-color: #7366FE;
  background-image: linear-gradient(90deg, #7366FE, #7366FE);
  height: 2.2rem;
  width: 9.5rem;
  margin-right: 13px;
  border-radius: 4px;
  border: none;
  color: #fff;
  position: relative;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
}

.butn::before {
  background: #fff;
  content: "";
  height: 155px;
  opacity: 0;
  position: absolute;
  top: -50px;
  transform: rotate(35deg);
  width: 50px;
  transition: all 3000ms cubic-bezier(0.19, 1, 0.22, 1);
}

.butn::after {
  background: #fff;
  content: "";
  height: 10rem;
  opacity: 0;
  position: absolute;
  top: -50px;
  transform: rotate(35deg);
  transition: all 3000ms cubic-bezier(0.19, 1, 0.22, 1);
  width: 4rem;
}

.butn__new::before {
  left: -50%;
}

.butn__new::after {
  left: -100%;
}

.butn:hover,
.butn:active {
  transform: translateY(-3px);
  color: #fff;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.3);
}

.butn__new:hover::before {
  left: 120%;
  opacity: 0.5s;
}

.butn__new:hover::after {
  left: 200%;
  opacity: 0.6;
}

.butn span {
  z-index: 20;
}

#highermarkFourdiv,
#highermarkFourdiv_,
#highermarkThreediv,
#highermarkThreediv_,
#highermarkTwodiv,
#highermarkTwodiv_,
#highermarkOnediv,
#highermarkOnediv_,
#higherBiologydiv,
#higherBiologydiv_,
#higherComputerSciencediv,
#higherComputerSciencediv_,
#higherMathsdiv,
#higherMathsdiv_,
#higherChemistrydiv,
#higherChemistrydiv_,
#higherPhysicsdiv,
#higherPhysicsdiv_,
#higherEnglishdiv,
#higherEnglishdiv_,
#highermarkSubjectOnediv,
#highermarkSubjectOnediv_,
#highermarkSubjectTwodiv,
#highermarkSubjectTwodiv_,
#highermarkSubjectThreediv,
#highermarkSubjectThreediv_,
#highermarkSubjectFourdiv,
#highermarkSubjectFourdiv_,
#highermaximummarkdiv {
  display: none;
}

#higherelevenmarkFourdiv,
#higherelevenmarkThreediv,
#higherelevenmarkTwodiv,
#higherelevenmarkOnediv,
#higherelevenBiologydiv,
#higherelevenComputerSciencediv,
#higherelevenMathsdiv,
#higherelevenChemistrydiv,
#higherelevenPhysicsdiv,
#highermarkdiv,
#higherpercentagediv,
#highercutoffdiv {
  display: none;
}

.pdfdesign a {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  width: 300px;
}

.backbutton {
  background: #7366FE;
  border-radius: 5px;
  padding: 8px 37px;
  color: #ffffff;
  text-align: center;
  margin-bottom: 10px;
  font-size: 16px;
  line-height: 20px;
  cursor: pointer;
  border-color: transparent;
}

.agreeterms {
  cursor: pointer;
}

.modal-content {
  margin-top: 30%;
}

.disclaimer {
  color: red;
}

#pleasewait {
  margin-top: 5px;
  color: red;
  font-weight: 600;
  text-align: right;
  display: none;
}

@media only screen and (min-device-width : 900px) and (max-device-width: 1400px) {
  .linedesign {
    width: 40px;
  }

  .linedesign-empty {
    width: 40px;
  }

  label {
    font-size: 15px;
  }

  .studentdashboardiconbox {
    padding: 5px 6px 5px 6px;
  }

  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 100%;
  }

  .col-md-1,
  .col-md-2,
  .col-md-3,
  .col-md-4,
  .col-md-5,
  .col-md-6,
  .col-md-7,
  .col-md-8,
  .col-md-9,
  .col-md-10,
  .col-md-11,
  .col-md-12 {
    padding: 0px 10px;
  }

  .studentdashboardheading {
    font-size: 15px;
  }

  .studentdashboardparagragh {
    font-size: 16px;
    line-height: 22px;
  }

  .icon-animated {
    margin-left: 40px;
  }

  .cardpadding {
    padding: 120px 80px 60px 80px !important;
  }
}

@media (max-width: 768px) {
  .savebutton {
    width: 110px;
    margin-right: 5px;
  }

  .nextbutton {
    width: 80px;
  }

  .backbutton {
    padding: 8px 20px;
  }

  .mobilemargintop15 {
    margin-top: -5px !important;
  }

  .completeapplication {
    margin-top: 10px;
  }

  .pdfdesign a {
    width: 256px;
  }

  .icon-animated {
    margin-left: 0px;
  }

  .pdfdesign {
    margin-bottom: 5px;
  }

  .timetakentext {
    font-size: 12px;
    line-height: 23px;
  }

  .supporttitle {
    font-size: 15px;
    line-height: 21px;
  }

  .modal-title {
    margin-left: 0rem;
  }

  .popupdate {
    font-size: 12.5px;
  }

  p {
    margin-bottom: 10px;
  }

  .margintop20 {
    margin-top: 10px;
  }

  .margintop40 {
    margin-top: 20px;
  }

  .col-md-1,
  .col-md-2,
  .col-md-3,
  .col-md-4,
  .col-md-5,
  .col-md-6,
  .col-md-7,
  .col-md-8,
  .col-md-9,
  .col-md-10,
  .col-md-11,
  .col-md-12 {
    padding: 0px 10px;
  }

  .bodycolor td {
    min-width: 200px;
  }

  .studentdashboardiconbox img {
    width: 75%;
  }

  .margintop10 {
    margin-top: 0px;
  }
}

.colorred {
  color: red;
}

.downloadicon {
  margin-top: -3px;
}

.student_Name {
  text-transform: capitalize;
}

.sidebar {
  background-color: #ffffff;
}

.arrowdesign {
  border: none;
  background: transparent;
}

.textcenter {
  text-align: center;
}

.announcementtext {
  color: #888888;
  font-size: 16px;
}

.importantdate {
  font-size: 18px;
}

.announcementbox {
  background: #F7F7F7;
  border-radius: 5px;
  padding: 15px 10px 15px 15px;
  margin-top: 20px;
}

.announcementboxright {
  background: #105C8E;
  border-radius: 5px;
  padding: 15px 10px 15px 15px;
  margin-top: 20px;
  color: #FFFFFF;
}

.entrancetext {
  font-size: 16px;
  color: #444444;
  font-style: italic;
  margin-bottom: 10px;
}

.entrancedate {
  margin-bottom: 3px;
}

.entranceplace {
  margin-bottom: 3px;
}

.padding25px {
  padding: 20px;
}

.clickhere {
  border-radius: 5px;
  cursor: pointer;
  background-color: #ffffff;
  border: none;
  font-weight: 600;
  color: #105C8E;
  text-align: center;
  font-size: 14px;
  padding: 7px;
  width: 200px;
  transition: all 0.5s;
}

.appliedfor {
  font-size: 17px;
  text-align: center;
}

.paramedical {
  font-size: 14px;
  text-align: center;
}

.hallticket {
  font-size: 17px;
  text-align: center;
}

.viewordownload {
  font-size: 14px;
  text-align: center;
}

.entrancemark td {
  padding-bottom: 10px;
}

.entrancemark b {
  padding: 0px !important;
}